import React, { useEffect, useState } from "react"
import {navigate} from 'gatsby'
import Col from "reactstrap/lib/Col"
import Container from "reactstrap/lib/Container"
import Row from "reactstrap/lib/Row"
import Layout from "./Layout"
import Metadata from "./Metadata"
import MovieCardLargeList from "./MovieCardLargeList"
import { getTagMovieTranslationlist, getTagRelatedTags } from "../api"
import { titleFromSlug } from "../utils"
import { useIntersectionObserver } from '@researchgate/react-intersection-observer';
import { Spinner } from "reactstrap"
import {Trans, Link, useTranslation} from 'gatsby-plugin-react-i18next';

export default props => {
    const errorMessage = null
    const [movies, setMovies] = useState([])
    const [watchlist, setWatchlist] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [nextPage, setNextPage] = useState(null)
    const [page, setPage] = useState(1)
    const [related, setRelated] = useState([])

    const {t} = useTranslation();

      
    const handleChange = async (entry) => {
      if (entry.isIntersecting && !isLoading){
        if (nextPage){
          setIsLoading(true)
          const result = await getTagMovieTranslationlist(props.slug, page)
          setPage(page + 1)
          setNextPage(result.next)
          setMovies([...movies, ...result.results])
        }
        setWatchlist(movies.filter(i => i.in_watchlist === true))
        setIsLoading(false)
      }
    };

    let ref = null
    if (typeof window !== 'undefined'){
      [ref] = useIntersectionObserver(handleChange, { threshold: 0, rootMargin:"0px 0px 1000px" });
    }

    const init = async (initSlug = null) => {
      const slug = initSlug ? initSlug : props.slug
      setMovies([])
      setIsLoading(true)
      try {
        const get_related = await getTagRelatedTags(slug)
        setRelated(get_related.results)
      } catch (err) {
        console.log(err)
      }
      const result = await getTagMovieTranslationlist(slug, 1)
      setPage(2)
      setMovies(result.results)
      setWatchlist(result.results.filter(i => i.in_watchlist === true))
      setNextPage(result.next)
      setIsLoading(false)
    }

    useEffect(() => {
      init()
    },[props.slug])

    const title = t(titleFromSlug(props.slug))
    const description = t(`tag: ${titleFromSlug(props.slug)}`)

    return (
      <div>
        <Metadata title={title} description={description}/>
          <Container>
            <div className="bg-white px-4 py-3 mb-3 mt-3">
              <h1 className="font-weight-bold text-dark text-left"> {title}</h1>
              <p className="text-left">{description}</p>
            </div>
            <Row className="mt-3">
              <Col md="8">
                {/* {!this.state.errorMessage ? */}
                  {/* <> */}
                    <MovieCardLargeList movies={movies} watchlist={watchlist}/>
                    {/* <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                        <li className={`page-item ${"page" === 1 ? 'disabled' : ''}`}>
                            <Link to={`${"path"}?page=${"page" - 1}`} className="page-link" aria-disabled={page === 1}>&laquo;</Link>
                        </li>
                        { pages }
                        <li className={`page-item ${page === totalPages || totalPages === 0 ? 'disabled' : ''}`}>
                            <Link to={`${path}?page=${page + 1}`} className="page-link" aria-disabled={page === totalPages || totalPages === 0}>&raquo;</Link>
                        </li>
                        </ul>
                    </nav>
                  </>
                  : <div className="text-secondary mt-4 text-center">{this.state.errorMessage}</div>
                } */}
                { isLoading ?
                  <div className="text-center m-5 text-5xl">
                      <Spinner/>
                  </div>
                  :
                  <div ref={ref} className="h-1"></div>
                }
              </Col>
              <Col md="4">
                <div className="bg-white px-4 py-3 mb-3">
                    <h6 className="font-weight-bold text-dark"><Trans>Similaire</Trans></h6>
                    <ul className="list-unstyled">
                    {related.map(i => (
                      <button type="button" className={`btn btn-sm btn-light mr-1 mb-1 w-100 text-left`} 
                      // onClick={() => {
                      //   navigate('/list/'+i)
                      //   // init(i)
                      // }
                      // } 
                    >
                      <Link to={'/list/'+i}>
                        {titleFromSlug(i)}
                      </Link>
                      </button>
                    )
                    )}
                    </ul>
                </div>
              </Col> 
            </Row>
          </Container>
      </div>
    )
}