import React from "react"
import { Router } from "@reach/router"
import {connect} from 'react-redux'
import Layout from "../components/Layout"
import List from "../components/List"
import { graphql } from "gatsby"

// import '../templates/movie.css'


const App = ({user, token}) => (
  <Layout>
    <Router>
      <List path="/list/:slug" user={user} token={token}/>
      <List path="/:lang/list/:slug" user={user} token={token}/>
    </Router>
  </Layout>
)

const mapStateToProps = state => ({
  user: state.user.user,
  token: state.user.token,
})

export default connect(mapStateToProps)(App)

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;